import React from "react";
import {
    Container,
    Header,
} from "@cloudscape-design/components";

export const NotFoundPage = () => {
    return (
        <Container
            header={<Header variant="h2" description="Dependency Sentinel" />}
        >
            Not Found
        </Container>
    );
};
