import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Container,
    Header,
} from "@cloudscape-design/components";
import {
    appendBreadCrumb,
    setPageTitle,
} from "src/web/redux/slices/ui";
import { SentinelPageProps } from "../../interfaces";
import { SentinelBasePage } from "../sentinel-base";

export interface SentinelTeamProfilePageProps extends SentinelPageProps {}

export const TeamProfilePage: React.FC<SentinelTeamProfilePageProps> = (
    // eslint-disable-next-line
    props: SentinelTeamProfilePageProps
) => {    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle("Team Profile"));
        dispatch(appendBreadCrumb({ text: "Team Profile", href: "/team" },));
    }, []);

    return (
        <SentinelBasePage header="Onboarded Team Profile">
            Team Profile
        </SentinelBasePage>
    );
};
