import React from "react";

import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import { SentinelBasePageProps } from "../interfaces";

export const SentinelBasePage: React.FC<SentinelBasePageProps> = (props) => (
    <Container
        header={
            <Header description={props.description ?? ""}>{props.header}</Header>
        }
    >
        {props.children}
    </Container>
);
