import React from "react";


interface PropsType {
  children: JSX.Element
}

/**
  * Simple class to manage the application initialization metric.
  *
  * Note this is meant as a demonstration of how to use the initialization metric, and
  * is almost certainly inadequate for a more complex application.
  *
  * It waits for itself to be mounted (if it is the outermost component that means all of
  * its children are rendered and mounted by React, though are not necessarily fully rendered
  * in the browser), then waits for the translations to be ready.
  *
  * In your actual application, you are likely not fully initialized until you have completed
  * your initial REST calls.  You will need to either integrate that logic here, or else move
  * the initialization metric elsewhere.
  *
  * For applications using a state manager (e.g. Redux) it may make more sense to determine if
  * your application is initialized from that layer instead.
  */
export class AppInitWrapper extends React.Component<PropsType, { hasError: boolean; }> {
    state = { hasError: false };

    constructor(props: any) {
        super(props);
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidMount() {
        console.log("Application redenred");
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
        console.log("Application threw an exception", error, info);
    }

    render() {
        if(this.state.hasError) {
            return <h1>Error</h1>;
        }

        return this.props.children;
    }
}

export default AppInitWrapper;
