import React from "react";
import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";

import { AppInitWrapper, SentinelLayout } from "./components";
import {
    LandingPage,
    NotFoundPage,
    //PermissionDeniedPage,
    OnboardingPage,
    TeamProfilePage,
} from "./pages";

export const App = () => {

    return (
        <AppInitWrapper>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SentinelLayout />}>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="onboard" element={<OnboardingPage />} />
                        <Route path="team" element={<TeamProfilePage />}/>
                    </Route>
                    <Route path="/*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </AppInitWrapper>
    );
};

export default App;
