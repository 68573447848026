import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
    Button, 
    Link,
} from "@cloudscape-design/components";
import {
    setPageTitle,
} from "src/web/redux/slices/ui";
import { SentinelPageProps } from "../../interfaces";
import { SentinelBasePage } from "../sentinel-base";
import { useNavigate } from "react-router-dom";
import "../../styles/app.css"

export interface SentinelOverviewPageProps extends SentinelPageProps {}

export const LandingPage: React.FC<SentinelOverviewPageProps> = (
    // eslint-disable-next-line
  props: SentinelOverviewPageProps
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle("Welcome to Dependency Sentinel"));
    }, []);
    return (
        <SentinelBasePage header="Overview">
            <img
                src={
                    "https://drive.corp.amazon.com/view/wanbojun@/logo-veed-remove-background.png"
                }
                style={{ width: "450px", height: "auto" }}
                alt="Description of the image"
            />
            <p>
                <span className="bolded">Dependency Sentinel</span> is a platform that will
                automatically generate code reviews for trivially updatable software.
            </p>
            <div>
                <p>The platform supports:</p>
                <ul>
                    <li> Scan the software packages and gather a list of dependencies.</li>
                    <li>
                    Periodically scan for new releases and updated guidance, apply the guidance and produce code reviews where applicable.
                    </li>
                    <li> Perform a basic test of the service by running unit tests</li>
                    <li> Submit a code review that team can review and merge</li>
                </ul>
            </div>
            <p>
        For more information about Dependency Sentinel and its usage details
        please refer to project&nbsp;
        <span className="bolded">
                    <Link
                        href="https://w.amazon.com/bin/view/DependencySentinel/Design"
                        external={true}
                    >
            Design Doc
                    </Link>
        </span>
        .
            </p>

            <Button
                variant="primary"
                onClick={() => navigate("onboard")}
            >
        Onboard Now
            </Button>
        </SentinelBasePage>
    );
};

export default LandingPage;
