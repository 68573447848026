import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
    name: "ui.AppLayout",
    initialState: {
        pageTitle: "Dependency Sentinel",
        sideNavOpen: false,
        helpPanelOpen: false,
        breadCrumbs: [
            { text: "Home", href: "/#" },
        ],
        infoMessages: [
            {
                type: "info",
                dismissible: true,
                content: "Development in progress",
                id: "message_1",
            },
        ],
    },
    reducers: {
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
        setSideNavOpen: (state, action) => {
            state.sideNavOpen = action.payload;
        },
        setHelpPanelOpen: (state, action) => {
            state.helpPanelOpen = action.payload;
        },
        appendBreadCrumb: (state, action) => {
            state.breadCrumbs.push(action.payload);
        },
        popBreadCrumb: (state) => {
            state.breadCrumbs.pop();
        },
        appendInfoMessage: (state, action) => {
            state.infoMessages.push(action.payload);
        },
    }
});

export const {
    setPageTitle,
    setSideNavOpen,
    setHelpPanelOpen,
    appendBreadCrumb,
    popBreadCrumb,
} = uiSlice.actions;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectPageTitle = (state: any) => state["ui.AppLayout"].pageTitle;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectSideNavOpen = (state: any) => state["ui.AppLayout"].sideNavOpen;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectHelpPanelOpen = (state: any) => state["ui.AppLayout"].helpPanelOpen;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectBreadCrumbs = (state: any) => state["ui.AppLayout"].breadCrumbs;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectInfoMessages = (state: any) => state["ui.AppLayout"].infoMessages;
